import * as React from "react"
import { graphql } from "gatsby"
import CanvasLayout from "../../components/layout/CanvasLayout"
import Container from "../../components/layout/Container"
import Type from "../../components/typography/Type"
import { isLoggedIn } from "../../services/auth"
import LoginPrompt from "../../components/app/loginPrompt"
import UserNeedCard from "../../components/cards/UserNeedCard"
import ProcessCard from "../../components/cards/ProcessCard"
import FeatureCard from "../../components/cards/FeatureCard"
import CapabilityCard from "../../components/cards/CapabilityCard"
import MomentCard from "../../components/cards/MomentCard"
import CanvasToolbar from "../../components/canvas/CanvasToolbar"
import Canvas from "../../components/canvas/Canvas"
import Button from "../../components/controls/Button"



import Toggle from "../../components/controls/Toggle"


import { useState } from "react"



export default function Pages( {data}) {

const [isGrid, toggleGrid] = useState(true);
const [isFeatures, toggleFeatures] = useState(true);
const [isNeeds, toggleNeeds] = useState(true);
const [isProcess, toggleProcess] = useState(true);
const [isCapabilities, toggleCapabilities] = useState(true);

const [isOptions, toggleOptions] = useState(false);

const columnWidth = "320px";
const horPadding = 0;
const verPadding = 12;

return (
  <>
  <CanvasLayout>
  {isLoggedIn() ? (

<div>
    
  <CanvasToolbar>
    <div><a href={data.airtable.data.ProjectPath}><span className="">&lt; Back to {data.airtable.data.ProjectTxt}</span></a></div>
    <div className="grow">
      <div className="flex flex-row justify-end space-x-8 lg:items-center">
        <div><button onClick={() => toggleOptions(!isOptions)} className="">Options</button></div>
        <div className={`${ isOptions ? `block` : `hidden` } flex flex-col lg:flex-row lg:justify-end space-y-2 lg:space-y-0 space-x-0 lg:space-x-8`}>
          <Toggle action="Grid" object={isGrid} switch={toggleGrid} explainer="(or stacked columns)" />
          <Toggle action="Products" object={isFeatures} switch={toggleFeatures} />
          <Toggle action="Needs" object={isNeeds} switch={toggleNeeds} />
          <Toggle action="Value Stream" object={isProcess} switch={toggleProcess} />
          <Toggle action="Capabilities" object={isCapabilities} switch={toggleCapabilities} />
        </div>  
      </div>
    </div>    
  </CanvasToolbar>
    
    
  <Canvas title={data.airtable.data.Name} introduction={data.airtable.data.Introduction}>  
 
    
    {data.airtable.data.Experiences  ?
    <div className={`grid grid-cols-1 gap-${verPadding}`}>
      
      <div className="flex flex-col lg:flex-row">   
           
  
        <div className="w-fit text-green uppercase p-[32px]">
          <div className="w-[320px]">
         <Type type="p4" nopadding="yes">Experiences</Type>
          </div>
         </div> 
     
           
           
        {data.airtable.data.Experiences.map((array, index) => (
          
    
                          
          
          <div key={index} className="z-2 ">
            <div className={`min-w-[${columnWidth}]`}>
            <>


              <div className="p-8 border-r border-blue bg-blue/50 h-40">
                <Type type="h5" nopadding="yes">{array.data.Name}</Type>
                <Type type="p7" nopadding="yes">{array.data.Introduction}</Type>
              </div>




              {array.data.Moments  ?  
           
             

              <div className={`flex flex-col lg:flex-row mt-${verPadding} relative`}>
              {index == 0 ?
                
                <div className="w-fit">
                 <div className="-left-[352px] absolute uppercase text-green"> <Type type="p4" nopadding="yes">Moments</Type></div>
                 </div>
                 
                 : 
                 undefined
              }
                 
                {array.data.Moments.map((MomentsArray) => (
                  
                  
                  <div className={`min-w-[${columnWidth}]`}>
                    
                    <MomentCard title={MomentsArray.data.Name} url={MomentsArray.data.Path}/>
                    
                    <div className={`h-36 mt-${verPadding}`}>
                    {MomentsArray.data.HeroImageURL
                      ?
                    <img src={`${MomentsArray.data.HeroImageURL}`}
                     alt={MomentsArray.data.Name}
                     className={`h-36 mx-auto`}
                     />
                     :
                     undefined
                   }
                    </div>
                    
                     <div className={`mt-${verPadding} h-10 hidden lg:block`}>
                        <div className="bg-yellow/30 h-4"/>
                        <div className="-mt-7 h-full w-10 bg-white border-black border-[6px] mx-auto z-20 flex items-center justify-center rounded-full">
                        </div>
                    </div>
                    
                     <div className={`mt-${verPadding} pr-12`}>
                       <p className={`${ isGrid ? `lg:overflow-auto lg:h-36` : `` } text-sm font-light `}>
                       {MomentsArray.data.Introduction}
                       </p>
                     </div>
                     
                     {MomentsArray.data.NeedsWhat
                       ?
                     <div className={`${ isNeeds ? `block` : `hidden ` } ${ isGrid ? `lg:overflow-auto lg:h-64` : `` } mt-${verPadding}`}>
                      {index == 0 ? <div className="absolute left-96 min-w-[320px] text-green uppercase p-8 "><Type type="p4" nopadding="yes">Needs</Type></div> : '' }
                        <ul className="list-disc">
                        {MomentsArray.data.NeedsWhat.map((UserNeedsArray) => (
                         <li className="ml-6 pr-12">             
                          <UserNeedCard 
                          archetype={UserNeedsArray.data.ArchetypeTxt}
                          need={UserNeedsArray.data.NeedLower}
                          purpose={UserNeedsArray.data.PurposeLower}
                          /></li>
                          ))} 
                        </ul>
                     </div>
                     :
                     undefined
                   }
                     {MomentsArray.data.Products
                       ?
                     <div className={`${ isFeatures ? `block` : `hidden ` } ${ isGrid ? `lg:overflow-auto lg:h-64` : `` } mt-${verPadding}` }>
                     {/*}
                     {index == 0 ? <div className="absolute left-96 min-w-[320px] h-full bg-white text-green uppercase p-8 "><Type type="p4" nopadding="yes">Products</Type></div> : '' }
                     */}
                      <div className="mr-4 grid grid-cols-1 gap-4">
                         {MomentsArray.data.Products.map((L4array) => (
                            <FeatureCard title={L4array.data.Name} url={L4array.data.Path}/>
                          ))}
                          <div className="flex items-center justify-center py-12">
                           <Button type="add" title="Add a Product" action="/new/products"/> 
                          </div>
                      </div>
                     </div>
                     :
                     <div className="flex items-center justify-center py-12">
                       <Button type="add" title="Add your first Product" action="/new/products"/> 
                      </div>
                   }
                     
                  </div>
                ))}
              </div>
              :
             <div className="flex items-center justify-center py-12">
              <Button type="add" title="Add a Moment" action="/new/moments"/> 
              </div>
            }
              
              
             {array.data.Module_BusinessProcessesL1
               ? 
              
              <div className={`${ isProcess ? `block` : `hidden ` } mt-${verPadding}`}>
              {/*}
                {index == 0 ? <div className={`absolute left-96 min-w-[320px] text-green h-full bg-white uppercase p-8 pb-${verPadding}`}><Type type="p4" nopadding="yes">Value Stream</Type></div> : '' }     
                */}       
                {array.data.Module_BusinessProcessesL1.map((BusinessProcessesArray) => (
                <>                                  
                  <img src={BusinessProcessesArray.data.ImageURL}
                   alt={BusinessProcessesArray.data.Name}
                   className="h-36"
                   />
                  <div className={`max-width-[${columnWidth-10}px] pr-[10px]`}>              
                    <ProcessCard level="level1" title={BusinessProcessesArray.data.Name}/>
                  </div>
                  <div className={`${ isGrid ? `lg:overflow-auto lg:max-h-64` : `` } mt-2`}>

                    <div className="flex flex-col lg:flex-row h-24x`">                              
                    {array.data.Moments.map((BlueprintsL3Array, index) => (
                    <>
                      <div className={`w-full min-w-[${columnWidth-10}px] pr-[10px]`}>
                        <ProcessCard level="level2" title={BlueprintsL3Array.data.ProcessTxt}/>
                      </div>  
                    </>
                    ))}
                    </div>
                  </div>
                </>
                ))}
              </div>      
                    
              :
             <div className="flex items-center justify-center py-12">
             <Button type="add" title="Add a Process" action="/new/process"/> 
             </div>
            }
              
              
             
             
            

             

             


            </>            
          </div>
          
        </div>
       
      ))}
       <div className="flex items-center justify-center py-12">
         <Button type="add" title="Add an Experience Step" action="/new/capability"/> 
        </div>
      

    </div>
      




      
    </div>
    
    :
    <div className="flex items-center justify-center py-12">
      <Button type="add" title="Add an Experience Step" action="/new/experiencesteps"/> 
    </div>
    }
    
    
    
    
    {data.airtable.data.Projects.map((ProjectArray) => (      
           <>
          
           {ProjectArray.data.CapabilityGroups
             
             ?
           
           <div className={`${ isCapabilities ? `block` : `hidden ` } mt-${verPadding}`}>
          
          <div className="flex flex-row">
            <div className="w-fit text-green uppercase p-[32px]">
              <div className="w-[320px]">
                <Type type="p4" nopadding="yes">Capabilities</Type>
              </div>
             </div>
             <div className="grow grid lg:grid-cols-5 xl:grid-cols-8 gap-8"> 
             {ProjectArray.data.CapabilityGroups.map((CapabilityGroupsArray) => (
             <>                                  
               <div className={`max-width-[${columnWidth}px]`}>              
                 <CapabilityCard level="level1" title={CapabilityGroupsArray.data.Name}>
                   <div className={`${ isGrid ? `lg:overflow-auto lg:max-h-64` : `` } mt-2`}>
     
                     <div className="grid grid-cols-1 gap-2">
                       {CapabilityGroupsArray.data.Capabilities.map((CapabilityArray) => (
                       <>
                       <CapabilityCard level="level2" title={CapabilityArray.data.Name}/>
                       </>
                       ))}
                     </div>  
     
                   </div>
                 </CapabilityCard>
               </div>
                 </>
             ))}
             </div>
          </div>
           </div>    
     
                
         :
        <div className="flex items-center justify-center py-12">
         <Button type="add" title="Add a Capability" action="/new/capability"/> 
        </div>
       }
       
       
       
         </>
     ))}
     
    
    </Canvas>
</div>
    
   ) : (
     <div>
       <LoginPrompt/>
     </div>     
   )}

  </CanvasLayout>
    </>

  )
}

export const Head = () => <title>Framework</title>


export const query = graphql`
  query GetPage($Path: String!) {
    airtable(table: { eq: "BlueprintsL1" },
    data: { Path: { eq: $Path } }) {
      data {
        Name
        Introduction
        ProjectTxt
        ProjectPath
        PrimaryColour
        SecondaryColour
        TertiaryColour
        Projects {data{
          Name
          Path
          CapabilityGroups {data {
            Name
            Capabilities {data {
              Name
            }}
          }}
        }}
        Journeys {data {
          Name
          Path
        }}
        Experiences {
          data {
            Name
            Path
            Introduction
            Module_BusinessProcessesL1
            {
              data
              { 
                Name
                ImageURL
                BusinessProcessesL2Txt
                Moments
                {data {
                  Name
                  Module_BusinessProcessesL2{data{Name}}
                }}
                Module_BusinessProcessesL2{data{Name}}
              }
            }
            
            Moments {
              data {
                Name
                Path
                HeroImageURL
                Order
                Introduction
                NeedsWhat 
                {
                  data {
                    Name
                    ArchetypeTxt
                    NeedLower
                    PurposeLower
                  }
                }
                ProcessTxt
                Module_BusinessProcessesL2
                {
                  data
                    {
                    Name
                    }
                }
                Products {
                  data 
                  {
                    Name
                    Path
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
